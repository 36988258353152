/* Calendar.css */
.react-calendar {
    width: 300px;
    max-width: 100%;
    background: white;
    border: 1px solid #ccc;
    font-family: Arial, sans-serif;
    border-radius: 10px; /* 테두리를 둥글게 만듭니다. */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 효과를 추가합니다. */
  }
  
  .react-calendar__month-view__weekdays {
    font-weight: bold;
  }
  
  .react-calendar__tile--active {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile--now {
    background: #d1e2f4;
  }
  
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd,
  .react-calendar__tile--rangeBothEnds {
    background: #2a88bd;
    color: white;
  }