.Oresbuttondiv {
    display: flex; /* 버튼을 가로로 나열 */
    justify-content: space-around; /* 버튼 사이에 균등한 간격 */
    margin-top: 20px; /* 상단 여백 */
}

.Oresbutton {
    background-color: #5D87FF; /* 기본 색상 */
    color: white; /* 버튼 텍스트 색상 */
    border: none; /* 버튼 테두리 없음 */
    border-radius: 20px; /* 모서리를 둥글게, pill-shaped */
    padding: 10px 20px; /* 버튼 내부 여백 */
    font-size: 14px; /* 버튼 텍스트 크기 */
    font-weight: 600; /* 텍스트 굵기 조절 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경 */
    transition: background-color 0.3s, transform 0.2s; /* 배경색 및 변환 애니메이션 */
    box-shadow: 0 4px 6px rgba(128, 203, 196, 0.1); /* 가벼운 그림자 추가 */
}

.Oresbutton:hover {
    background-color: #5D87FF; /* 마우스를 올렸을 때 배경색 변경 */
    transform: translateY(-2px); /* 약간 위로 이동 */
    box-shadow: 0 6px 8px rgba(102, 166, 158, 0.2); /* 그림자 강도 증가 */
}

.Oresbutton:active {
    background-color: #5D87FF; /* 버튼을 클릭했을 때 배경색 변경 */
    transform: translateY(0); /* 원래 위치로 이동 */
    box-shadow: 0 4px 6px rgba(128, 203, 196, 0.1); /* 원래 그림자 */
}

.Oresbutton:focus {
    outline: none; /* 버튼을 클릭했을 때 외곽선 제거 */
    box-shadow: 0 0 0 3px rgba(128, 203, 196, 0.4); /* 포커스 시 그림자 추가 */
}
