@media screen and (max-width: 400px) {
    #about,
    #services,
    #testimonials,
    #contact,
    #portfolio,
    #footer {
      width: 100%;
    }
  }





  