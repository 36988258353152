.review-content-input {
    width: 100%;
    min-height: 150px;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 20px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
    line-height: 1.5; /* 줄간격을 조정 */
    resize: none; /* 크기 조정 없애기 */
  }

  .star {
    display: flex;
    justify-content: center; /* 가로 방향 중앙 정렬 */
    align-items: center; /* 세로 방향 중앙 정렬 */
    position: relative; /* top 속성을 사용하기 위해 position 속성 설정 */
    top: 70px; /* 원하는 위치로 이동 (픽셀 단위) */
    left : 10px;
  }


