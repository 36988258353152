.input-field {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 12px;
    width: 100%;
    max-width: 300px;
    transition: border-color 0.3s ease; /* 테두리 색상 변화에 대한 transition 효과 추가 */
  }
  
  .input-field:focus,
  .input-field:not(:placeholder-shown),
  .input-field:not(:placeholder-shown):focus {
    border-color: #5d87ff; /* 파란색 테두리로 변경 */
  }
  
  .input-field.valid {
    border-color: #5d87ff;
  }
  
  .input-field.invalid {
    border-color: #ff5d5d;
  }


  .acouns_img {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 10%;
    object-fit: cover;
    display: block;
    border: 3px solid #edeff2; /* 테두리 색상을 세련되게 설정 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 효과 추가 */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* 변환 및 그림자 효과에 대한 부드러운 전환 추가 */
  }


  /* 이미지 */
  .Counselor_img_class {
    cursor: pointer; /* 이미지 클릭 가능하게 커서 변경 */
  }
  
  .Counselor_img {
    object-fit: cover; /* 이미지가 잘리지 않고 크기에 맞게 조정되도록 설정 */
    border-radius: 50%; /* 이미지가 원형이 되도록 설정, 필요 시 제거 또는 조정 */
  }

  /* 체크박스 */
  .pretty-checkbox {
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }
  
  .pretty-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }
  
  .pretty-checkbox label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin-bottom: 12px;
  }
  
  .pretty-checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }
  
  .pretty-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #5c9bea; /* 파란색 */
    border-color: #5c9bea; /* 파란색 */
  }
  
  .pretty-checkbox label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 6px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }
  
  .pretty-checkbox input[type="checkbox"]:checked + label:after {
    display: block;
  }




  /*Counselor_info_detail5*/

   .Counselor_info_detail5 {
    width: 100%;
    background: #ffffff;
    color: #333;
    padding: 25px;
    border-radius: 16px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }

  .Counselor_info_detail5 {
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 20px;
  }

    /*Counselor_info_detail4*/

    .Counselor_info_detail4 {
      width: 50%;
      height : 80%;
      background: #ffffff;
      color: #333;
      padding: 25px;
      border-radius: 16px;
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    }
  
    .Counselor_info_detail4 {
      display: flex;
      text-align: left;
      flex-direction: column;
      gap: 20px;
    }



  /* 전문분야 box */
  .Counselor_info_detail3 {
    width: 45%; 
    background: #ffffff;
    color: #333;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .Counselor_info_detail_row {
    display: flex;
    justify-content: space-between; 
    flex-wrap: wrap;
  }
  
  .Counselor_info_detail3.left {
    width: calc(50% - 3px); 
    margin-right: 3px; 
  }
  
  .Counselor_info_detail3.right {
    width: calc(50% - 3px); 
    margin-left: 3px; 
  }

  /*전문분야 삭제 아이콘 */

  .button-container {
    display: flex;
    justify-content: flex-end; /* 자식 요소들을 오른쪽으로 정렬 */
    width: 100%; /* 부모 요소의 너비를 100%로 설정 */
  }
  
  .icon-button {
    width: 20px; /* 버튼 너비 */
    height: 20px; /* 버튼 높이 */
    border: none; /* 테두리 없음 */
    background-color: transparent; /* 배경 색상 없음 */
    cursor: pointer; /* 마우스 포인터 변경 */
  }
  
  .close-icon {
    width: 100%; /* 이미지 너비 */
    height: 100%; /* 이미지 높이 */
    object-fit: contain; /* 이미지 채우기 */
  }

  @media (max-width: 875px) {
    .Counselor_main_container {
      width: 95%;
    }
    
    .Counselor_sub_container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .Counselor_info {
      width: 100%;
    }
  
    .Counselor_info_detail4 {
      width: 100%;
    }
  }
  
  
  
  

  
  
  


  
  
  

  