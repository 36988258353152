/* Login.css */

.login-title {
    margin: 15px;
    padding: 40px;
    font-size: 25px;
  }
  
  .login-container {
    margin: 20px;
    width: 40%;
    margin: 50px auto;
    padding: 80px;
    height: 350px;
    border: 1px solid hsl(0, 0%, 80%);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center; /* 중앙 정렬 */
    font-size: 20px;
    justify-content: center; /* 수평 정렬 */
  }
  
  /* 입력 상자와 버튼을 감싸는 div에 대한 스타일 */
  .login-container div {
    margin-bottom: 10px;
  }
  
  /* 로그인 버튼 스타일 */
  .login-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 50px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  /* 회원가입 링크 스타일 */
  .login-container p a {
    color: #007bff;
    text-decoration: none;
    font-size : 12px;
  }
  
  .login-container p a:hover {
    text-decoration: underline;
  }
  
  .email-box1 {
    width: 80px;
  }

  .email-box2 {
    width: 88px;
  }
  
  