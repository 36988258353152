


.title {
    margin: 15px;
    padding: 40px;
    font-size: 25px;
  }


  .signup-container {
    margin: 20px;
    width: 40%;
    margin: 30px auto;
    padding: 50px;
    height: 400px;
    border: 1px solid hsl(0, 0%, 80%);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center; /* 중앙 정렬 */
    justify-content: center; /* 수평 정렬 */
    font-size: 13px;
  }

  .signup-container p {
    margin-bottom: 15px; /* 또는 원하는 만큼의 여백 설정 */
}

  .client-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 50px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 20px;

  }


  .counselor-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 50px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 20px;
  }