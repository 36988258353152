:root {
  --table-header-bg-color: #9db6cc;
  --button-bg-color: #1E88E5;
  --button-hover-bg-color: #1565C0;
  --border-color: #CFD8DC;
  --font-size: 14px;
  --header-font-size: 16px;
  --font-family: 'Noto Sans KR', sans-serif;
  --row-hover-bg-color: #E3F2FD;
}

.community_table {
  width: 100%;
  margin-top: 6rem;
  height: auto;
}

.common-table-header-column {
  background-color: var(--table-header-bg-color);
  color: #FFFFFF;
  padding: 12px;
  font-size: var(--header-font-size);
  font-weight: bold;
  text-transform: uppercase;
}

.common-table-row:nth-child(odd) {
  background-color: #f7f7f7;
}

.common-table-column {
  padding: 12px;
  border-bottom: 1px solid var(--border-color);
  font-size: var(--font-size);
  transition: background-color 0.3s;
}


.common-table-column a {
  color: var(--table-header-bg-color);
  text-decoration: none;
  transition: color 0.3s;
}

.common-table-column a:hover {
  color: var(--button-hover-bg-color);
}
