

hr {
    width : 90%;
   
  }


.join_title{
    margin-top: 70px;
    margin-right: 1125px;
    padding : 10px;
    font-size : 15px;
    
}

.join_p {
    padding: 11px;
    font-size : 10px;
    text-align : left;
}

.join-input {
  width: 55px;
  height: 20px;
}

.join-input2 {
  width: 80px;
  height: 20px;
}

.join-input3 {
    width: 1100px;
    height: 100px;
}

h5 {
    text-align : left;
}


.white-box {
    background : white;
    width: 80%;
    height: 20px;
    margin: 2px auto ;
    display: flex;
    color: black;
    padding: 10px;
    font-weight: 500;
    font-size: 10px;
  }

  .join_button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 50px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 10px;
  }

  .delete-button {
    font-size: 12px;
    padding: 5px 8px;
   
  }

  .box-info {
    width: 1100px;
    margin: 3px auto;
    height: 100px;
    overflow: auto; /*스크롤 */
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 13px;
    white-space:pre-wrap;
  }




  