

.bank_p{
    padding: 5px;
    font-size : 13px;
    text-align : center;
    margin-top: 10px ;

}


.bank-container {
    margin: 20px;
    width: 30%;
    margin: 50px auto;
    padding: 80px;
    height: 200px;
    border: 1px solid hsl(0, 0%, 80%);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center; /* 중앙 정렬 */
    font-size: 20px;
    justify-content: center; /* 수평 정렬 */
  }

