/* postlist */

.postlistbox {
  width: 100%;
  height: 100%;
  background: #f4f7fa;
  padding-top: 10rem;
  padding-bottom: 0rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


@media (min-width: 912px) and (max-width: 1024px) {
  .postlistbox {
    padding-bottom: 0rem;
  }
}

.comunitytitle {
  width: 95%;
  height: 5vh;
  padding: 0 0 10px 10px;
  text-align: left;
  margin: auto;
  margin-bottom: 10px;
  color: #2c3e50;
  border-left: 0.5em solid #5d87ff;
  display: flex;
  align-items: center;
}

.posttablebox {
  width: 100%;
  height: auto;
}

.common-table {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  border-spacing: 0;
  border-collapse: separate;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  flex-direction: column;
}

.common-table-header {
  display: flex;
  width: 100%;
  background-color: #5d87ff;
  color: #FFFFFF;
  font-weight: bold;
  text-transform: uppercase;
}

.common-table-row {
  display: flex;
  width: 100%;
}

.common-table-row:nth-child(odd) {
  background-color: #f7f7f7;
}

.common-table-row:hover .common-table-column {
  background-color: #E3F2FD;
}

.common-table-column {
  padding: 12px;
  transition: background-color 0.3s;
  box-sizing: border-box;
}

.common-table-column a {
  text-decoration: none;
  transition: color 0.3s;
}

.communitylistindex {
  flex: 1;
}

.communitylisttitle {
  flex: 6;
}

.communitylistnickname {
  flex: 3;
}

.communitylistdate {
  flex: 1;
}

.communitylistview {
  flex: 1;
}

/* 작성 버튼 */
.communicatebutton {
  width: 95%;
  margin-top: 1rem;
  text-align: right;
}

.common-button {
  background-color: #5d87ff;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}


.common-button:hover {
  background-color: #4570ea;
  transform: scale(1.1);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

/* 페이지네이션 */
.pagenationcomponent {
  margin-top: 10px;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
}

/* postview */
.postviewcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  margin: 0 auto; 
  padding-top: 10rem;
  
  background: #f4f7fa;
}

.post-view-wrapper {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  background: #fff;
  
  @media screen and (max-width: 1200px) {
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
}
  
.post-view-go-list-btn {
  border: 0;
  padding: 10px;
  color: white;
  background-color: #5d87ff;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 7px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-view-go-list-btn:hover {
  background-color: #4570ea;
}

  .post-box {
    background: white;
    width: 300px;
    height: 50px;
    text-align: left;
    display: flex;
    border: 1px solid black;
    font-weight: 500;
    font-size: 10px;
    position: relative; /* 상대적인 위치 설정 */
    top: 10px; 
    left : 110px;
    
  }

  .firstpost,
  .secondpost,
  .thirdpost {
    width: 100%;
    display: flex;
    justify-content: space-between; /* 요소 간 간격 동일하게 수정 */
    align-items: center;
    margin: 5px auto; /* 여백 수정 */
  }

  .posttitle {
    width: 50%;
    font-size: 1.5em;
    text-align: left;
  }
  .view {
    width: 50%;
    text-align: right;
  }

  .nickname {
    width: 50%;
    text-align: left;
  }
  .postnum {
    width: 25%;
    text-align: right;
  }
  .writedate {
    width: 25%;
    text-align: right;
  }

  .contentfield {
    white-space: pre-wrap;
    word-wrap: break-word; 
    background-color: initial;
    border: none;
    min-height: 200px;
    text-align: left;
    
    overflow-x: hidden;
  }

.contenthr {
  width: 100%;
  border: none;
  height: 1px;
  background-color: #808080;
  margin: 20px 0;
}

  /* 댓글 */

  .commentdiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    align-items: center; /* 수직 정렬 */
    justify-content: center; /* 수평 정렬 */
  }

  .commentinput {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 20px;

    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    @media screen and (max-width: 1200px) {
      width: 90%;
    }
  
    @media screen and (max-width: 480px) {
      width: 95%;
    }
  }

  .inputcomment1 {
    width: 100%;
    display: flex;
  }
  .inputcomment2 {
    width: 100%;
    display: flex;
  }
  
  .inputnickname {
    width: 8%;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    
  .inputcontent {
    width: 8%;
    margin-top: 10px;
    margin-right: 10px;
    padding: 1%;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .nicknameinput {
    width: 100%;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
  }
  
  .contentinput {
    width: 100%;
    margin-right: 10px;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
  }
  
  .nicknameinput:focus, .contentinput:focus {
    border-color: #5d87ff;
  }
  
  .post-commentinput {
    width: 7%;
    padding: 0.8%;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #5d87ff;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    @media screen and (max-width: 1200px) {
      width: 12%;
      padding: 1%;
    }
  
    @media screen and (max-width: 480px) {
      width: 20%;
      padding: 1.5%;
    }
  }
  
  .post-commentinput:hover {
    background-color: #4570ea;
  }
  
  
  .commenttitle {
    font-size: 1.5em;
    margin-bottom: 1.5em;
  }

  .comment-item {
    width: 60%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    @media screen and (max-width: 1200px) {
      width: 90%;
    }
  
    @media screen and (max-width: 480px) {
      width: 95%;
    }
  }
  
  
  .comment-info {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
  }
  
  .comment-number {
    width: 5%;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .comment-writer {
    width: 47%;
    font-weight: bold;
    text-align: left;
  }
  
  .comment-date {
    width: 47%;
    text-align: right;
  }

  .comment-content-div {
    width: 100%;
    display: flex;
  }

  .comment-content {
    white-space: pre-wrap;
    word-wrap: break-word; 
    margin-top: 10px;
    width: 80%;
    text-align: left;
  }

  .comment-button {
    width: 20%;
    text-align: right;
  }
  
  .commentbtn {
    background-color: #5d87ff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .commentbtn:hover {
    background-color: #4570ea;
  }
  
  
  .hr2 {
    width: 60%;
    border: none; /* 기본 테두리 제거 */
    height: 1px; /* 높이 설정 */
    background-color: #808080; /* 회색으로 변경 */
    margin: 5em 5em; /* 구분선 위아래 여백을 조정합니다. */
  
    @media screen and (max-width: 1200px) {
      width: 90%;
    }
  
    @media screen and (max-width: 480px) {
      width: 95%;
    }
  }

/* create, change.css */
.create-first {
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 5px;
  justify-content: center;
}

.create-nickname {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* 수직 가운데 정렬 */
}

.createtitleinputlabel, .createnicknameinputlabel {
  width: 10%;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.createnicknameinputlabel2 {
  width: 90%;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 15.5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
  text-align: left;
}

.createnicknameinput, .createtitleinput{
  width: 90%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.create-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* 수직 가운데 정렬 */
}


.create-content-input {
  min-height: 200px;
}

.create-second {
  width: 100%;
  height: 100%;
}

.create-content-input {
  width: 100%;
  min-height: 400px;
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.create-post-button {
  margin-top: 2%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #5d87ff; /* 버튼 배경색 설정 */
  color: #fff; /* 버튼 텍스트 색상 설정 */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 부드러운 색상 변화 효과 */
}

.create-post-button:hover {
  background-color: #4570ea; /* 호버 시 색상 변화 */
}


/* modal.css */
.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff; /* 백그라운드 색상 */
  border-radius: 8px;
  width: 800px;
  max-width: 90%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05); /* 그림자 효과 */
}

.custom-modal .modal-content {
  text-align: center;
  padding: 3rem;
}

.custom-modal .modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333333;
}

.custom-modal .modal-content div {
  margin-bottom: 1rem; /* 하단 여백 */
}

.custom-modal .modal-content textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  font-size: 1.3rem; /* 입력 폼 글자 크기 */
  border: 1px solid #ced4da; /* 테두리 색상 */
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical; /* 세로 리사이즈 허용 */
  margin-bottom: 1rem;
}

.custom-modal .modal-content button {
  padding: 10px 20px; /* 버튼 안쪽 여백 */
  margin: 8px;
  background-color: #5d87ff; /* 버튼 배경색 */
  color: #ffffff; /* 버튼 글자색 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-modal .modal-content button:hover {
  background-color: #4570ea; /* 호버 시 배경색 변화 */
}



/* 반응형 */
@media (max-width: 660px) {
  .communitylistnickname {
    display: none;
  }

  .communitylistdate {
    display: none;
  }
  
  .communitylistview {
    display: none;
  }
}

@media (max-width: 850px) {
  .createtitleinputlabel, .createnicknameinputlabel{
    width: 25%;
  }
}


@media (max-width: 350px) {
  .createtitleinputlabel, .createnicknameinputlabel{
    width: 30%;
  }
}

@media (max-width: 660px) {
  .inputnickname, .inputcontent {
    width: 20%;
  }
}

@media (max-width: 1205px) {
  .comment-content-div {
    flex-direction: column;
  }

  .comment-content {
    width: 100%;
    margin-bottom: 10px;
  }

  .comment-button {
    width: 100%;
  }

  .commentbtn {
    padding: 8px 16px;
  }
}