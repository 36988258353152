* {
  box-sizing: border-box;
}

.Counselor_res_div {
  width: 100%;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.Counselor_res_title {
  width: 80%;
  height: 5vh;
  padding: 0 0 10px 10px;
  text-align: left;
  margin: auto;
  margin-bottom: 10px;
  color: #2c3e50;
  border-left: 0.5em solid #5d87ff;
  display: flex;
  align-items: center;
}

.Counselor_res_detail {
  background: #ffffff;
  color: #333;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: auto;
}

.Counselor_res_cal, .Counselor_res_info, .Counselor_res_button {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.Counselor_res_choice {
  width: 100%;
  height: 68vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.Counselor_res_cal {
  width: 50%;
  height: 68vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.Counselor_res_date, .Counselor_res_time {
  flex: 1;
  background: transparent;
}

.Counselor_res_date .react-calendar {
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
}

.Counselor_res_date .react-calendar__tile--now {
  background: #73b2dc;
  color: white;
}

.Counselor_res_date .react-calendar__tile--active {
  background: #2980b9;
  color: white;
}

.Counselor_res_time {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.Counselor_time_buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.Counselor_time_buttons button {
  background-color: #5d87ff;
  border: none;
  padding: 8px 12px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.2s;
  color: #fff;
}

.Counselor_time_buttons button:not(.disabled):hover {
  background-color: #4570ea;
  transform: scale(1.03);
}

.Counselor_time_button.disabled {
  background-color: grey;
  color: darkgrey;
  cursor: not-allowed;
  transform: none;
}

.Counselor_time_buttons button.selected {
  background-color: #233a79;
}

.Counselor_res_info {
  width: 50%;
  height: 68vh;
  text-align: center;
  margin: auto;
}

.Counselor_res_info_table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.Counselor_res_info_table th,
.Counselor_res_info_table td {
  padding: 16px 20px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.Counselor_res_info_table th {
  background-color: #5d87ff;
  color: white;
  font-weight: 500;
}

.Counselor_res_info_table tr:last-child td {
  border-bottom: none;
}

.Counselor_res_info_table td {
  color: #333;
}

.Counselor_res_info_table tr:nth-child(even) td {
  background-color: #f8f9fa;
}

.Counselor_res_info_table tr:hover {
  background-color: #e9f4ff;
}

.Counselor_res_info_table td:first-child {
  color: #5d87ff;
  font-weight: 600;
}

.Counselor_res_transparent-box {
  background: rgba(255, 255, 255, 0.8); /* 투명도 설정 */
  padding: 2rem; /* 내부 여백 */
  border-radius: 10px; /* 둥근 모서리 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  max-width: 600px; /* 최대 너비 설정 */
  margin: 0 auto; /* 중앙 정렬 */
}

.reservation_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem; /* 각 예약 항목 간의 간격 */
  font-size: 1.2rem; /* 글자 크기 */
}

.minusbutton, .plusbutton {
  background-color: #5d87ff; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 둥근 모서리 */
  padding: 0.5rem 1rem; /* 내부 여백 */
  cursor: pointer; /* 커서 모양 변경 */
  transition: background-color 0.3s; /* 호버 효과 */
}

.minusbutton:hover, .plusbutton:hover {
  background-color: #4570ea; /* 호버 시 배경색 변경 */
}

.Counselor_res_button {
  text-align: center;
  margin-top: 2rem;
  padding: 2rem;
}

.Counselor_res_button button {
  background-color: #5d87ff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.Counselor_res_button button:disabled {
  background-color: grey;
  color: darkgrey;
  cursor: not-allowed;
  transform: none;
}

.Counselor_res_button button:not(:disabled):hover {
  background-color: #4570ea;
  transform: scale(1.03);
}


/* Group_reservation.js */
* {
  box-sizing: border-box;
}

.G_Counselor_res_div {
  width: 100%;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.G_Counselor_res_title {
  width: 80%;
  height: 5vh;
  padding: 0 0 10px 10px;
  text-align: left;
  margin: auto;
  margin-bottom: 10px;
  color: #2c3e50;
  border-left: 0.5em solid #5d87ff;
  display: flex;
  align-items: center;
}

.G_Counselor_res_detail {
  background: #ffffff;
  color: #333;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: auto;
}

.G_Counselor_res_cal, .G_Counselor_res_info, .G_Counselor_res_button {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.G_Counselor_res_choice {
  width: 100%;
  height: 68vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.G_Counselor_res_cal {
  width: 50%;
  height: 68vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.G_Counselor_res_date, .G_Counselor_res_time {
  flex: 1;
  background: transparent;
}

.G_Counselor_res_date .react-calendar {
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
}

.G_Counselor_res_date .react-calendar__tile--now {
  background: #73b2dc;
  color: white;
}

.G_Counselor_res_date .react-calendar__tile--active {
  background: #2980b9;
  color: white;
}

.G_Counselor_res_time {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.G_Counselor_time_buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.G_Counselor_time_buttons button {
  background-color: #5d87ff;
  border: none;
  padding: 8px 12px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.2s;
  color: #fff;
}

.G_Counselor_time_buttons button:not(.disabled):hover {
  background-color: #4570ea;
  transform: scale(1.03);
}

.G_Counselor_time_button.disabled {
  background-color: grey;
  color: darkgrey;
  cursor: not-allowed;
  transform: none;
}

.G_Counselor_time_buttons button.selected {
  background-color: #233a79;
}

.G_Counselor_res_info {
  width: 50%;
  height: 68vh;
  text-align: center;
  margin: auto;
}

.G_Counselor_res_info_table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.G_Counselor_res_info_table th,
.G_Counselor_res_info_table td {
  padding: 16px 20px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.G_Counselor_res_info_table th {
  background-color: #5d87ff;
  color: white;
  font-weight: 500;
}

.G_Counselor_res_info_table tr:last-child td {
  border-bottom: none;
}

.G_Counselor_res_info_table td {
  color: #333;
}

.G_Counselor_res_info_table tr:nth-child(even) td {
  background-color: #f8f9fa;
}

.G_Counselor_res_info_table tr:hover {
  background-color: #e9f4ff;
}

.G_Counselor_res_info_table td:first-child {
  color: #5d87ff;
  font-weight: 600;
}

.G_Counselor_res_transparent-box {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.G_reservation_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.G_reervation_count_title {
  width: 50%;
  text-align: left;
}

.G_Counselor_res_button {
  text-align: center;
  margin-top: 1.5rem;
  padding: 2rem;
}

.G_Counselor_res_button button {
  background-color: #5d87ff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.G_Counselor_res_button button:disabled {
  background-color: grey;
  color: darkgrey;
  cursor: not-allowed;
  transform: none;
}

.G_Counselor_res_button button:not(:disabled):hover {
  background-color: #4570ea;
  transform: scale(1.03);
}

/* Reservation 반응형 */
@media (max-width: 770px) {
  .Counselor_res_choice, .Counselor_res_cal {
    height: 73vh;
  }
}


@media (max-width: 751px) {
  .Counselor_res_detail {
    width: 90%;
    height: 160vh;
  }

  .Counselor_res_choice {
    display: flex;
    flex-direction: column;
  }

  .Counselor_res_button {
    margin-top: 50.5rem;
  }
}

@media (max-width: 377px) {
  .Counselor_res_detail {
    width: 95%;
    height: 190vh;
  }

  .Counselor_res_choice {
    display: flex;
    flex-direction: column;
  }

  .Counselor_res_button {
    margin-top: 51rem;
  }
}

/* Group_Reservation 반응형 */
@media (max-width: 852px) {
  .G_Counselor_res_detail {
    height: 210vh;
  }

  .G_Counselor_res_choice {
    width: 100%;
    flex-direction: column;
  }
  
  .G_Counselor_res_cal {
    width: 100%;
  }
  
  .G_Counselor_res_info {
    width: 100%;
  }

  .G_Counselor_res_button {
    margin-top: 50rem;
  }
}

@media (max-width: 751px) {
  .G_Counselor_res_detail {
    width: 90%;
  }
}

@media (max-width: 377px) {
  .G_Counselor_res_detail {
    width: 95%;
  }
}