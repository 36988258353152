/* Counselor.js */
* {
  box-sizing: border-box;
}

.Counselorrootdiv {
  width: 100%;
  padding-top: 11rem;
  padding-bottom: 10rem;
}


.Counselor_main_container {
  background: #ffffff;
  color: #333;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: auto;
}

.Counselor_sub_container {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  
  margin-bottom: 20px;
}

.Counselor_info, .Counselor_info_detail {
  width: 50%;
  background: #ffffff;
  color: #333;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.Counselor_info {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.Counselor_img_class {
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.Counselor_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Counselor_info_detail {
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 20px;
}

.Counselor_career_content, .Counselor_edu_content, .Counselor_license_content {
  margin-left: 12px;
}

.Counselor_review_detail {
  display: flex;
  flex-direction: column;
  text-align: left;

  background: #ffffff;
  color: #333;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.Counselor_review_title {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 10px;
  align-items: center;
}

.Counselor_review_board {
  margin-top: 25px;
}

.review-card {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Counselor_review_content {
  width: 100%;
  padding-top: 10px;
  text-align: left;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis; 
}
.Counselor_review_button {
  width: 100%;
  text-align: right;
}

.review_detail_button {
  background-color: transparent;
  border: none;
  color: #5d87ff;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  padding-top: 7px;
}

.review_dialog_content {
  width: 100%;
  text-align: left;
  line-height: 1.5;
  word-wrap: break-word; 
}

.Counselor_Reservation_button {
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 25px;
  gap: 20px;
}

.Counselor_h4 {
  display: flex;
  position: relative;
  padding-left: 20px;
  text-align: left;
  margin-bottom: 10px;
  color: #2c3e50;
  height: 1.5em;
  line-height: 1.5em;
}

.Counselor_h4::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.3em;
  height: 100%;
  background-color: #5d87ff;
}


@media (min-width: 876px) and (max-width: 1000px) {
  .Counselor_main_container {
    width: 90%;
  }
}

@media (max-width: 875px) {
  .Counselor_main_container {
    width: 95%;
  }
  
  .Counselor_sub_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Counselor_info {
    width: 100%;
  }

  .Counselor_info_detail {
    width: 100%;
  }
}