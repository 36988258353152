.hr {
  width: 90%;
  border-color: #5D87FF; /* 원하는 색상으로 변경 */
}

.join_title {
  margin-top: 70px;
  text-align: left;
  font-size: 15px;
  margin-left : 70px;
}

.join_p {
  /*padding: 11px;*/
  font-size: 13px;
  text-align: left;
}

.join-input {
  width: 40px;
  height: 13px;
}

.join-input2 {
  width: 80px;
  height: 20px;
}

.join-input3 {
  width: 1100px;
  height: 100px;
}

h5 {
  text-align: left;
}

.white-box {
  background: white;
  width: 80%;
  height: 20px;
  margin: 2px auto;
  display: flex;
  color: black;
  padding: 10px;
  font-weight: 500;
  font-size: 10px;
}

.join_button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 10px;
}

.box-info {
  width: 1100px;
  margin: 3px auto;
  height: 100px;
  overflow: auto; /*스크롤 */
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 13px;
  white-space: pre-wrap;
  text-align: left;
  border-radius: 8px; 
}

.signup-re-agree-box {
  width: 80%;
  margin: 3px auto;
  height: 160px;
  overflow: auto; /*스크롤 */
  border: 1px solid #ccc;
  font-size: 13px;
  white-space: pre-wrap;
  text-align: center;
  border-radius: 10px; /* 둥근 테두리 */
}

.hr-join {
  width: 90%;
  background-color: #5D87FF;
  border-width: 0px;
}


.joindiv {
  width: 100%;
  padding: 11rem 10rem 5rem 10rem;
} 

.joinTitle {
  width: 100%;
  height: 5vh;
  text-align: left;
  margin: auto;
  margin-bottom: 10px;
  color: #2c3e50;
  display: flex;
  align-items: center;
}


.joinfield {
  width: 100%;
  display: flex;
  align-items: left;
  flex-direction: column;
}

