.requestdiv {
  width: 100%;
  padding: 11rem 10rem 5rem 10rem;
} 

.requestTitle {
  width: 100%;
  height: 5vh;
  text-align: left;
  margin: auto;
  margin-bottom: 10px;
  color: #2c3e50;
  display: flex;
  align-items: center;
  font-Family: "'Noto Sans KR', 'Nanum Square', sans-serif";
  font-Weight: 500;
  margin-bottom: 5px;
  font-size: 12px;
}

.requestfield {
  width: 100%;
  display: flex;
  align-items: left;
  flex-direction: column;
}

.join_p {
  font-size: 13.5px;
  margin-bottom: 8px;
  line-height: 1.5;
}

.re-agree-box {
    width: 100%;
    margin: 3px auto;
    height: 300px;
    overflow: auto; /*스크롤 */
    border: 1px solid #ccc;
    font-size: 13px;
    white-space: pre-wrap;
    text-align: center;
    border-radius: 10px; /* 둥근 테두리 */
  }
  
  .hr-res {
    width: 100%;
    border: 1px solid #5D87FF;
    background-color: #5D87FF;
  }

  .radio-label {
    padding-right: 5px;
  }

  @media (max-width: 716px) {
    .requestdiv {
      width: 100%;
      padding: 11rem 5rem 5rem 5rem;
    } 
  }

  @media (max-width: 465px) {
    .requestdiv {
      width: 100%;
      padding: 11rem 2rem 5rem 2rem;
    } 
  }