

.login-title {
  margin: 15px;
  padding: 40px;
  font-size: 25px;
}

.login-container {
  width: 40%;
  margin: 50px auto;
  padding: 80px;
  height: 350px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center; /* 중앙 정렬 */
  font-size: 20px;
  justify-content: center; /* 수평 정렬 */
}

.login-container div {
  margin-bottom: 10px;
}

.login-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.login-container p a {
  color: #007bff;
  text-decoration: none;
  font-size: 12px;
}

.login-container p a:hover {
  text-decoration: underline;
}

/* 반응형 스타일 */

@media screen and (max-width: 768px) {
  .login-container {
    width: 80%; /* 화면 너비의 80%로 조정 */
    padding: 40px; /* 패딩 조정 */
    height: auto; /* 고정 높이에서 자동 높이로 변경 */
  }
  
  .login-title {
    font-size: 20px; /* 제목 폰트 크기 조정 */
  }
  
  .login-button {
    padding: 10px 30px; /* 버튼 패딩 조정 */
  }
  
  .login-container p a {
    font-size: 10px; /* 링크 폰트 크기 조정 */
  }
}


.kakao-login-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.kakao-login-button img {
  width: 55%;
  height: auto;
}
