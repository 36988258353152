/* paymentdiv 스타일 */
.paymentdiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding-top: 12rem;
  padding-bottom: 5rem;
}

/* 전체 페이지 컨테이너 */
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* 그리드 컨테이너 */
.grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* 왼쪽 그리드 아이템 */
.left-grid-item {
  width: 100%;
}

/* 내부 카드 스타일 */
.inner-card {
  width: 100%;
}

/* 카드 내용 스타일 */
.card-content {
  font-size: 14.5px;
  font-weight: 700;
}

.card-subcontent {
  font-size: 13.5px;
  font-weight: 400;
}

/* 결제 UI 및 이용약관 UI 영역 */
.payment-widget,
.agreement {
  width: 100%;
}
