/* 기본적인 설정 */
body {
  margin: 0;
  padding: 0;
  font-family: 'NanumSquare', sans-serif;
  background-color: #f4f7fa; /* 부드러운 배경 색상 */
}

/* 컨테이너 설정 */
.search.container {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
  opacity: 0;
  animation: fadeInAnimation ease 0.5s;
  animation-fill-mode: forwards;
}

/* 검색 타이틀 설정 */
.search-title {
  width: 100%;
  height: 5vh;
  padding: 0 0 10px 10px;
  text-align: left;
  margin: auto;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  border-left: 0.45em solid #5d87ff;
  display: flex;
  align-items: center;
}

/* 검색 행 설정 */
.search_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  align-items: stretch; /* 자식 요소들을 동일한 높이로 설정 */
}

/* 검색 박스 설정 */
.search-box {
  height: 100%;
  transition: transform 0.3s ease, scale 0.3s ease;
}

.search-box:hover {
  transform: translateY(-5px) scale(1.05); /* 호버 시 위로 이동 및 크기 증가 */
}

.search-box > * {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 이미지 설정 */
.couns_img_div {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  position: relative;
  border-radius: 25px 25px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #71a4d9;
  bottom: -40px;
}

.couns_img_class {
  width: 13rem;
  height: 13rem;
  position: relative;
  border-radius: 50%;
  background: #FFF;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;

  bottom: -20px;
  z-index: 5;
}

.couns_img {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  border: 4px solid #71a3d9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* overlay */
.search_overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 25px 25px 0 25px;
}

.search_overlay::before,
.search_overlay::after {
  content: '';
  position: absolute;
  background-color: #fff;
  width: 0;
  height: 0;
}

.search_overlay::before {
  right: 0;
  top: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid #fff;
}

.search_overlay::after {
  left: 0;
  top: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid #fff;
}


/* 제목 설정 */
.couns_content_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  position: relative;
  background: #FFF;
  border-radius: 25px 25px 25px 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  width: 50vh;
  height: 30vh;
  transition: transform 0.3s ease, box-shadow 0.3s ease, scale 0.3s ease;
}

.couns_content_div:hover {
  transform: translateY(-5px) scale(1.05); /* 호버 시 위로 이동 및 크기 증가 */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); /* 호버 시 그림자 증가 */
}

.couns-name-ratings {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.couns-name {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.ratings {
  display: flex;
  align-items: center;
}

/* 설명 설정 */
.couns-script {
  font-size: 14px;
  color: #707070;
  text-align: center;
  line-height: 1.5;
  padding-top: 1rem;
  margin: 10px auto;
  height: 50%;
  
}

@keyframes fadeInAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* 더보기 버튼 */
.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.load-more-button {
  background-color: #4d94ff; 
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.load-more-button:hover {
  background-color: #3767af;
  transform: translateY(-2px);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
}

.load-more-button:active {
  background-color: #4d94ff;
  transform: translateY(0);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* 미디어 쿼리: 화면이 작아질 때 */
@media (min-width: 993px) and (max-width: 1200px) {
  .search-box {
    width: calc(33%);
    margin: 0 auto;
  }

  .couns_img_div {
    width: 27vw;
  }

  .couns_content_div {
    width: 32vw;
    height: 35vh;
  }
}

@media (min-width: 786px) and (max-width: 992px) {
  .search-box {
    width: calc(47%);
    margin: 0 auto;
  }

  .couns_img_div {
    width: 28vw;
  }

  .couns_content_div {
    width: 33vw;
    height: 33vh;
  }
}

@media (min-width: 670px) and (max-width: 786px) {
  .search_row {
    display: flex;
    flex-direction: column;
  }
  
  .couns_img_div {
    width: 55vw;
  }

  .couns_content_div {
    width: 65vw;
    height: 30vh;
  }
}

@media (max-width: 670px) {
  .search_row {
    display: flex;
    flex-direction: column;
  }
  
  .couns_img_div {
    width: 70vw;
    bottom: -25px;
  }

  .couns_content_div {
    width: 80vw;
    height: 23vh;
  }
}



/* 정렬 */


.filters {
  display: flex;
  justify-content: flex-start;
  gap: 20px; /* 두 필터 사이의 간격 */
  margin-bottom: 20px;
}

.filter {
  display: flex;
  align-items: center;
}

.filter select {
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%; /* 드롭다운 메뉴 너비를 100%로 설정 */
}