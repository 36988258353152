.Counselor_time_calendar_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Counselor_res_cal, .Counselor_res_info, .Counselor_res_button {
    background: #ffffff;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .Counselor_res_cal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .Counselor_res_date, .Counselor_res_time {
    flex: 1;
    background: transparent;
  }
  
  .Counselor_res_date .react-calendar {
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
  }
  
  .Counselor_res_date .react-calendar__tile--now {
    background: #73b2dc;
    color: white;
  }
  
  .Counselor_res_date .react-calendar__tile--active {
    background: #2980b9;
    color: white;
  }

.Counselor_time_info {
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
}

  .Counselor_res_time {
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }
  
  /* 공통 버튼 스타일 */
  .Counselor_time_button {
    background-color: #5d87ff;
    border: none;
    padding: 8px 12px;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.2s;
    color: #fff;
  }
  
  /* disabled 버튼 스타일 */
  .Counselor_time_button.disabled {
    background-color: grey;
    color: darkgrey;
    cursor: not-allowed;
    transform: none;
  }
  
  /* block 버튼 스타일 */
  .Counselor_time_button.block {
    background-color: rgb(188, 188, 188);
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  /* hover 효과 */
  .Counselor_time_buttons button:not(.disabled):hover {
    background-color: #4570ea;
    transform: scale(1.03);
  }
  
  /* 선택된 시간 버튼 스타일 */
  .Counselor_time_buttons button:not(.disabled).selected {
    background-color: #233a79;
  }
  
  
  




  .Counselor_res_info {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  
  .Counselor_res_info_table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .Counselor_res_info_table th,
  .Counselor_res_info_table td {
    padding: 16px 20px;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .Counselor_res_info_table th {
    background-color: #5d87ff;
    color: white;
    font-weight: 500;
  }
  
  .Counselor_res_info_table tr:last-child td {
    border-bottom: none;
  }
  
  .Counselor_res_info_table td {
    color: #333;
  }
  
  .Counselor_res_info_table tr:nth-child(even) td {
    background-color: #f8f9fa;
  }
  
  .Counselor_res_info_table tr:hover {
    background-color: #e9f4ff;
  }
  
  .Counselor_res_info_table td:first-child {
    color: #5d87ff;
    font-weight: 600;
  }

  .Con_time_calendar_button {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }