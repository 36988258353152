/* O_Reservation.js */
.O_Reservation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 5rem;
  position: relative;
}

.O_Reservation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://wallcoveringsmart.com/cdn/shop/products/500045_off_white_cream_textured_plain_wallcoverings_wallpaper_5.jpg?v=1649617564');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.3; /* 불투명도 조절 */
  z-index: -1; /* 오버레이를 뒤로 보내기 */
}

.programs {
  width: 80%;
  min-height: calc(100vh - 5rem); /* 100vh에서 헤더 높이를 뺀 값 */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.program-card {
  width: 30%;
  cursor: pointer;
  border: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.program-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.program-card img {
  width: 100%;
  height: auto;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transition: transform 0.3s ease-in-out;
}

.program-card:hover img {
  transform: scale(1.1);
}

.program-info {
  padding: 1.5rem;
}

.program-info h2 {
  margin: 0.5rem 0;
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
}

.program-info p {
  margin: 0;
  color: #666;
  line-height: 1.6;
}

/* O_Reservation_edu */
.O_Res {
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 10rem;
  background-color: #f4f7fa;
}

.O_Res_Container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  margin-top: 5rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.O_Res_Container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
}

.O_Res_Container_h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 700;
}

.O_Res_Container_p {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #555;
}

.O_Res_Content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}

.accordion {
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}

.accordion-title {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #f4f4f4, #ffffff);
  border-radius: 12px;
  transition: background 0.3s;
  margin-bottom: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.accordion-title:hover {
  background: linear-gradient(to right, #eaeaea, #f4f4f4);
}

.accordion-content {
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  line-height: 1.6;
}

.contentimg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}

.contentimg img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
}

.contentimg:hover img {
  transform: scale(1.05);
}

.O_reservation-button {
  width: 100%;
  padding: 15px 30px;
  margin-top: 30px;
  font-size: 1.25rem;
  color: #ffffff;
  background-color: #5d87ff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.O_reservation-button:hover {
  background-color: #4570ea;
}

/* O_Reservation_detail */
* {
  box-sizing: border-box;
}

/* 전체 컨테이너 */
.OReservationdetailContainer {
  background: #f4f7fa;
  width: 100%;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.OResDetailTitle {
  width: 80%;
  height: 5vh;
  padding: 0 0 10px 10px;
  text-align: left;
  margin: auto;
  margin-bottom: 10px;
  color: #2c3e50;
  border-left: 0.5em solid #5d87ff;
  display: flex;
  align-items: center;
}

.Oreservationdetail {
  background: #ffffff;
  color: #333;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: auto;
}

/* 섹션 스타일 */
.ODetailtype, .OResCal, .Oresdetailinfo, .Oresnextbuttondiv {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* 달력 및 시간 선택 스타일 */
.ODetailchoice {
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.OResCal {
  width: 50%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.OResdate, .ORestime {
  flex: 1;
  background: transparent;
}

.OResdate .react-calendar {
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
}

.OResdate .react-calendar__tile--now {
  background: #73b2dc;
  color: white;
}

.OResdate .react-calendar__tile--active {
  background: #2980b9;
  color: white;
}

.ORestime {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.O-time-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* 필요에 따라 조정하세요 */
  flex-wrap: wrap;
  justify-content: center;
}

.O-time-buttons button {
  background-color: #5d87ff;
  border: none;
  padding: 8px 12px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.2s;
  color: #fff;
}

.O-time-buttons button:not(.disabled):hover {
  background-color: #4570ea;
  transform: scale(1.1);
}

.O-time-button.disabled {
  background-color: grey;
  color: darkgrey;
  cursor: not-allowed;
  transform: none;
}

.O-time-buttons button.selected {
  background-color: #233a79;
}

/* 수강 인원 입력 스타일 */
.Oresclientcountdiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.OresP {
  width: 50%;
  margin-right: 5px;
}


.Orescount_input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #5d87ff;
  background: transparent;
  color: #333;
  border-radius: 20px;
  text-align: center;
}

.Oresdetailinfo {
  width: 50%;
  height: 75vh;
  text-align: center;
  margin: auto;
}

.Oresdetailinfo-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.Oresdetailinfo-table th,
.Oresdetailinfo-table td {
  padding: 16px 20px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.Oresdetailinfo-table th {
  background-color: #5d87ff;
  color: white;
  font-weight: 500;
}

.Oresdetailinfo-table tr:last-child td {
  border-bottom: none;
}

.Oresdetailinfo-table td {
  color: #333;
}

.Oresdetailinfo-table tr:nth-child(even) td {
  background-color: #f8f9fa;
}

.Oresdetailinfo-table tr:hover {
  background-color: #e9f4ff;
}

.Oresdetailinfo-table td:first-child {
  color: #5d87ff;
  font-weight: 600;
}

.Oresnotice {
  background-color: #f9f9f9;
  border-left: 5px solid #5d87ff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.Oresnotice p {
  color: #333;
  line-height: 1.5;
  margin: 10px 0;
  font-size: 16px;
  text-align: left;
}

/* 다음 단계 버튼 스타일 */
.Oresnextbuttondiv {
  text-align: center;
  margin-top: 20px;
}

/* 기본 버튼 스타일 */
.Oresnextbuttondiv button {
  background-color: #5d87ff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
}

/* 버튼이 비활성화되었을 때의 스타일 */
.Oresnextbuttondiv button:disabled {
  background-color: grey;
  color: darkgrey;
  cursor: not-allowed;
  transform: none;
}

/* 버튼이 활성화되었을 때의 스타일 */
.Oresnextbuttondiv button:not(:disabled):hover {
  background-color: #4570ea;
  transform: scale(1.03);
}


@media (max-width: 850px) {
  .O_Reservation {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }

  .programs {
    display: flex;
    flex-direction: column;
  }

  .program-card {
    width: 100%;
  }

  .program-card img {
    width: 100%;
    height: 30vh;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    transition: transform 0.3s ease-in-out;
  }

  .O_Res_Content {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .O_Res_Container {
    width: 95%;
  }
  .O_Res_Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
}

@media (min-width: 1045px) and (max-width: 1215px) {
  .OResCal {
    height: 80vh;
  }

  .Oresdetailinfo {
    height: 80vh;
  }

  .Oresnextbuttondiv {
    text-align: center;
    margin-top: 50px;
  }

  .ORestime {
    height: 80vh;
  }
}

@media (min-width: 870px) and (max-width: 1045px) {
  .OResCal {
    height: 85vh;
  }

  .Oresdetailinfo {
    height: 85vh;
  }

  .Oresnextbuttondiv {
    text-align: center;
    margin-top: 90px;
  }
}

@media (max-width: 869px) {
  .Oreservationdetail {
    width: 90%;
    height: 175vh;
  }

  .ODetailchoice {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .OResCal {
    width: 100%;
  }

  .Oresdetailinfo {
    width: 100%;
  }

  .Oresnextbuttondiv {
    margin-top: 60.5rem;
  }
}

@media (max-width: 570px) {
  .Oreservationdetail {
    height: 180vh;
  }

  .Oresdetailinfo {
    height: 95vh;
  }
  
  .Oresnotice {
    height: 45%;
  }

  .Oresnextbuttondiv {
    margin-top: 64.5rem;
  }
}

@media (max-width: 525px) {
  .Oreservationdetail {
    height: 188vh;
  }

  .Oresnextbuttondiv {
    margin-top: 68rem;
  }
}